import React, { useState, useEffect } from 'react';
import { Card, Stack, Button, Drawer } from '@mui/material';
import {
  LISTING_COLUMNS,
  LISTING_ACTIONS,
} from '../../config/module-configs/users-migration.config';
import ListData from '../table-elements/list-data.component';
import Iconify from '../common/iconify.component';
import FilterUsers from './filter-migration-users.component';
import { getMigratedSubscribers } from '../../services/admin-reports.service';

const UsersMigration = () => {
  const columns = LISTING_COLUMNS;
  const actions = LISTING_ACTIONS;

  const [options, setOptions] = useState({
    page: 0,
    rowsPerPage: 100,
    totalRows: 0,
    rows: [],
    reloadCounter: 0,
    sortBy: '',
    sortOrder: '',
    error: false,
    loading: true,
    appliedFilters: {
      search: '',
      channels: [],
      platforms: [],
      migrationStatus: null,
    },
    filtersCounter: 0,
  });
  const [openFilters, setOpenFilters] = useState(false);

  const handlePageChange = (v) => {
    setOptions({
      ...options,
      page: v,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };
  const handleRowsPerPageChange = (v) => {
    setOptions({
      ...options,
      rowsPerPage: v,
      page: 0,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };
  const handleSortingChange = (sortBy, sortOrder) => {
    setOptions({
      ...options,
      sortBy,
      sortOrder,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };
  const handleRefreshData = () => {
    setOptions({
      ...options,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };

  const handleToggleFilters = () => {
    setOpenFilters(!openFilters);
  };
  const handleApplyFilters = (selectedFilters) => {
    let counter = 0;
    if (selectedFilters.search.trim()) counter += 1;
    if (selectedFilters.channels.length > 0) counter += 1;
    if (selectedFilters.platforms.length > 0) counter += 1;
    if (selectedFilters.migrationStatus !== null) counter += 1;

    setOptions({
      ...options,
      appliedFilters: {
        ...selectedFilters,
      },
      filtersCounter: counter,
      page: 0,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
    handleToggleFilters();
  };
  const handleClearFilters = () => {
    handleToggleFilters();
    setOptions({
      ...options,
      appliedFilters: {
        search: '',
        channels: [],
        platforms: [],
        migrationStatus: null,
      },
      filtersCounter: 0,
      page: 0,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };

  useEffect(() => {
    const params = [];
    params.push(`page=${options.page + 1}`);
    params.push(`perPage=${options.rowsPerPage}`);
    if (options.sortBy && options.sortOrder) {
      params.push(`sortBy=${options.sortBy}`);
      params.push(`sortOrder=${options.sortOrder}`);
    }
    const filters = options.appliedFilters;
    if (filters.search) {
      params.push(`q=${encodeURIComponent(filters.search)}`);
    }
    if (filters.channels.length > 0) {
      const channels = [];
      filters.channels.forEach((c) => channels.push(c.id));
      params.push(`channels=${channels.join(',')}`);
    }
    if (filters.platforms.length > 0) {
      const platforms = [];
      filters.platforms.forEach((p) => platforms.push(p.id));
      params.push(`platforms=${platforms.join(',')}`);
    }
    if (filters.migrationStatus !== null) {
      params.push(`migrationStatus=${filters.migrationStatus}`);
    }

    const paramsQuery = params.length > 0 ? `?${params.join('&')}` : '';
    getMigratedSubscribers(paramsQuery)
      .then((res) => {
        setOptions({
          ...options,
          loading: false,
          totalRows: res?.data?.totalRows || 0,
          rows: res?.data?.rows || [],
          error: false,
        });
      })
      .catch(() => {
        setOptions({
          ...options,
          loading: false,
          page: 0,
          totalRows: 0,
          rows: [],
          error: true,
        });
      });
  }, [options.reloadCounter]);

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ px: 1, py: 2 }}
      >
        <div>&nbsp;</div>
        <div>
          <Button
            color={options.filtersCounter > 0 ? 'warning' : 'inherit'}
            variant="contained"
            startIcon={<Iconify icon="ic:round-filter-list" />}
            sx={{ marginRight: 2 }}
            onClick={handleToggleFilters}
          >
            Filters
            {options.filtersCounter > 0 ? ` (${options.filtersCounter})` : ''}
          </Button>
          <Button
            color="inherit"
            variant="contained"
            startIcon={<Iconify icon="ic:twotone-refresh" />}
            onClick={handleRefreshData}
            disabled={options.loading}
          >
            Refresh
          </Button>
        </div>
      </Stack>

      <Card>
        <ListData
          columns={columns}
          rows={options.rows}
          page={options.page}
          rowsPerPage={options.rowsPerPage}
          totalRows={options.totalRows}
          loading={options.loading}
          actions={actions}
          error={options.error}
          sortBy={options.sortBy}
          sortOrder={options.sortOrder}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          onSortChange={handleSortingChange}
        />
      </Card>

      <Drawer
        anchor="right"
        open={openFilters}
        onClose={handleToggleFilters}
        PaperProps={{
          sx: { width: 300, border: 'none', overflow: 'hidden' },
        }}
      >
        <FilterUsers
          values={options.appliedFilters}
          onCancel={handleToggleFilters}
          onSuccess={handleApplyFilters}
          onClear={handleClearFilters}
        />
      </Drawer>
    </>
  );
};

export default UsersMigration;

// Define application wide routes
const ROUTE_PATH = {
  LOGIN: '/login',
  DASHBOARD: '/',
  REALTIME_DASHBOARD: '/realtime-dashboard',
  CONTENT_CHANNELS: '/engagement/apps',
  CONTENT_VIDEOS: '/engagement/videos',
  CONTENT_PLAYLISTS: '/engagement/playlists',
  SUBSCRIBERS: '/users/all',
  ADMIN_USERS: '/settings/access',
  SMTP_SETTINGS: '/settings/email',
  BILLING_SUBSCRIPTIONS: '/users/subscribers',
  BILLING_PAYMENTS: '/users/payments',
  REPORTS_OVERVIEW: '/reports/overview',
  REPORTS_LIVE_USERS: '/reports/live-users',
  REPORTS_SESSIONS: '/engagement/video-sessions',
  REPORTS_APP_SESSIONS: '/engagement/app-sessions',
  REPORTS_MIGRATION_STATUS: '/reports/migration',
  REPORTS_MIGRATION_USERS: '/reports/migration-users',
  REPORTS_ACTIVE_SUBSCRIBERS: '/reports/active-subscribers',
  REPORTS_TRIAL_SUBSCRIBERS: '/reports/trial-subscribers',
  REPORTS_CHANNEL_ALL_USERS: '/reports/users-count-overview',
  LOGOUT: '/logout',
};

export default ROUTE_PATH;

import React, { useState, useEffect } from 'react';
import { Card, Stack, Button, Drawer } from '@mui/material';
import { ACTIONS } from '../../config/const.config';
import {
  LISTING_COLUMNS,
  LISTING_ACTIONS,
} from '../../config/module-configs/live-users.config';
import { formatDate } from '../../utils/datetime.util';
import ListData from '../table-elements/list-data.component';
import Iconify from '../common/iconify.component';
import ViewSession from './view-session.component';
import FilterSessions from './filter-sessions.component';
import { getReportsData } from '../../services/reports.service';

const ListLiveUsers = () => {
  const columns = LISTING_COLUMNS;
  const actions = LISTING_ACTIONS;

  const [options, setOptions] = useState({
    page: 0,
    rowsPerPage: 10,
    totalRows: 0,
    rows: [],
    reloadCounter: 0,
    sortBy: '',
    sortOrder: '',
    error: false,
    loading: true,
    appliedFilters: {
      startDate: null,
      endDate: null,
      channels: [],
      subscribers: [],
      videos: [],
      platforms: [],
      types: [],
      ip: '',
      deviceId: '',
    },
    filtersCounter: 0,
  });
  const [doAction, setDoAction] = useState({
    data: null,
    action: null,
  });
  const [openFilters, setOpenFilters] = useState(false);

  const handlePageChange = (v) => {
    setOptions({
      ...options,
      page: v,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };
  const handleRowsPerPageChange = (v) => {
    setOptions({
      ...options,
      rowsPerPage: v,
      page: 0,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };
  const handleSortingChange = (sortBy, sortOrder) => {
    setOptions({
      ...options,
      sortBy,
      sortOrder,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };
  const handleRefreshData = () => {
    setOptions({
      ...options,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };
  const handleActionSelect = (selectedAction, selectedData) => {
    setDoAction({
      ...doAction,
      data: selectedData || null,
      action: selectedAction || null,
    });
  };
  const handleActionCancel = () => {
    setDoAction({
      ...doAction,
      data: null,
      action: null,
    });
  };

  const handleToggleFilters = () => {
    setOpenFilters(!openFilters);
  };
  const handleApplyFilters = (selectedFilters) => {
    let counter = 0;
    if (selectedFilters.startDate) counter += 1;
    if (selectedFilters.endDate) counter += 1;
    if (selectedFilters.channels.length > 0) counter += 1;
    if (selectedFilters.subscribers.length > 0) counter += 1;
    if (selectedFilters.videos.length > 0) counter += 1;
    if (selectedFilters.platforms.length > 0) counter += 1;
    if (selectedFilters.types.length > 0) counter += 1;
    if (selectedFilters.ip.trim()) counter += 1;
    if (selectedFilters.deviceId.trim()) counter += 1;

    setOptions({
      ...options,
      appliedFilters: {
        ...selectedFilters,
      },
      filtersCounter: counter,
      page: 0,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
    handleToggleFilters();
  };
  const handleClearFilters = () => {
    handleToggleFilters();
    setOptions({
      ...options,
      appliedFilters: {
        startDate: null,
        endDate: null,
        channels: [],
        subscribers: [],
        videos: [],
        platforms: [],
        types: [],
        ip: '',
        deviceId: '',
      },
      filtersCounter: 0,
      page: 0,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };

  useEffect(() => {
    const payload = {
      reportType: 'live_users',
      page: options.page + 1,
      perPage: options.rowsPerPage,
    };
    if (options.sortBy && options.sortOrder) {
      payload.sortBy = options.sortBy;
      payload.sortOrder = options.sortOrder;
    }
    if (options.sortBy && options.sortOrder) {
      payload.sortBy = options.sortBy;
      payload.sortOrder = options.sortOrder;
    }
    const filters = options.appliedFilters;
    if (filters.startDate) {
      const startDate = formatDate(filters.startDate, 'YYYY-MM-DD');
      payload.startDate = startDate;
    }
    if (filters.endDate) {
      const endDate = formatDate(filters.endDate, 'YYYY-MM-DD');
      payload.endDate = endDate;
    }
    if (filters.channels.length > 0) {
      const channels = [];
      filters.channels.forEach((c) => channels.push(c.id));
      payload.channels = channels;
    }
    if (filters.subscribers.length > 0) {
      const subscribers = [];
      filters.subscribers.forEach((c) => subscribers.push(c.id));
      payload.subscribers = subscribers;
    }
    if (filters.videos.length > 0) {
      const videos = [];
      filters.videos.forEach((c) => videos.push(c.id));
      payload.videos = videos;
    }
    if (filters.platforms.length > 0) {
      const platforms = [];
      filters.platforms.forEach((p) => platforms.push(p.id));
      payload.platforms = platforms;
    }
    if (filters.types.length > 0) {
      const types = [];
      filters.types.forEach((c) => types.push(c.id));
      payload.types = types;
    }
    if (filters.ip) {
      payload.ip = [filters.ip];
    }
    if (filters.deviceId) {
      payload.devices = [filters.deviceId];
    }

    getReportsData(payload)
      .then((res) => {
        setOptions({
          ...options,
          loading: false,
          totalRows: res?.data?.totalRows || 0,
          rows: res?.data?.rows || [],
          error: false,
        });
      })
      .catch(() => {
        setOptions({
          ...options,
          loading: false,
          page: 0,
          totalRows: 0,
          rows: [],
          error: true,
        });
      });
  }, [options.reloadCounter]);

  return (
    <>
      <Stack direction="row" justifyContent="right" sx={{ px: 1, py: 2 }}>
        <Button
          color={options.filtersCounter > 0 ? 'warning' : 'inherit'}
          variant="contained"
          startIcon={<Iconify icon="ic:round-filter-list" />}
          sx={{ marginRight: 2 }}
          onClick={handleToggleFilters}
        >
          Filters
          {options.filtersCounter > 0 ? ` (${options.filtersCounter})` : ''}
        </Button>
        <Button
          color="inherit"
          variant="contained"
          startIcon={<Iconify icon="ic:twotone-refresh" />}
          onClick={handleRefreshData}
          disabled={options.loading}
        >
          Refresh
        </Button>
      </Stack>

      <Card>
        <ListData
          columns={columns}
          rows={options.rows}
          page={options.page}
          rowsPerPage={options.rowsPerPage}
          totalRows={options.totalRows}
          loading={options.loading}
          actions={actions}
          error={options.error}
          sortBy={options.sortBy}
          sortOrder={options.sortOrder}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          onSortChange={handleSortingChange}
          onAction={handleActionSelect}
        />
      </Card>

      <Drawer
        anchor="right"
        open={openFilters}
        onClose={handleToggleFilters}
        PaperProps={{
          sx: { width: 300, border: 'none', overflow: 'hidden' },
        }}
      >
        <FilterSessions
          values={options.appliedFilters}
          onCancel={handleToggleFilters}
          onSuccess={handleApplyFilters}
          onClear={handleClearFilters}
        />
      </Drawer>

      {doAction.action === ACTIONS.VIEW.value && (
        <ViewSession
          title={`Session Details - ${doAction.data.sessionId}`}
          dataId={doAction.data.id}
          onCancel={handleActionCancel}
        />
      )}
    </>
  );
};

export default ListLiveUsers;

import CELL_DATA_TYPES from '../cell-types.config';

// Subscribers module configurations
export const LISTING_COLUMNS = [
  {
    id: 'channel',
    label: 'Channel',
    width: '15%',
    align: 'left',
    dataKey: 'channel',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'signupFromPlatform',
    label: 'Platform',
    width: '10%',
    align: 'center',
    dataKey: 'signupFromPlatform',
    sortable: true,
    type: CELL_DATA_TYPES.PLATFORM,
  },
  {
    id: 'name',
    label: 'Name',
    width: '14%',
    align: 'left',
    dataKey: 'name',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'username',
    label: 'Username',
    width: '14%',
    align: 'left',
    dataKey: 'username',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'email',
    label: 'Email',
    width: '17%',
    align: 'left',
    dataKey: 'email',
    sortable: true,
    type: CELL_DATA_TYPES.EMAIL,
  },

  {
    id: 'migrationStatus',
    label: 'Migration Status',
    width: '10%',
    align: 'center',
    dataKey: 'migrationStatus',
    sortable: true,
    type: CELL_DATA_TYPES.MIGRATION_STATUS,
  },
  {
    id: 'subscriptionStatus',
    label: 'Active Subscription',
    width: '10%',
    align: 'left',
    dataKey: 'subscriptionStatus',
    sortable: true,
    type: CELL_DATA_TYPES.SUBSCRIPTION_SKU,
  },
  {
    id: 'migratedSubscription',
    label: 'Imported Subscription',
    width: '10%',
    align: 'left',
    dataKey: 'migratedSubscription',
    sortable: true,
    type: CELL_DATA_TYPES.SUBSCRIPTION_SKU,
  },
];

export const LISTING_ACTIONS = [];

import React from 'react';
import { Grid } from '@mui/material';
import AppLayout from '../../layout/app.layout';
import Counters from '../../components/reports/counters.component';
import WatchedSecondsChart from '../../components/reports/watched-seconds-chart.component';
import DailyWatchedSecondsCSV from '../../components/reports/daily-watched-seconds-csv';

const DashboardView = () => {
  const pageTitle = 'Home';

  return (
    <AppLayout pageTitle={pageTitle}>
      <Counters />

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <WatchedSecondsChart />
        </Grid>
        <Grid item xs={12}>
          <DailyWatchedSecondsCSV />
        </Grid>
      </Grid>
    </AppLayout>
  );
};

export default DashboardView;

// Define commonly used constants
export const LS_ADMIN_USER_AUTH_TOKEN = '__pnm_admin_t';
export const LS_ADMIN_USER_NAME = '__pnm_admin_n';
export const LS_ADMIN_USER_EMAIL = '__pnm_admin_e';
export const LS_ADMIN_USER_ROLE = '__pnm_admin_r';

export const VALID_ADMIN_ROLES = {
  SUPER_ADMIN: 'SUPER_ADMIN',
  ADMIN: 'ADMIN',
  CONTENT_OWNER: 'CONTENT_OWNER',
};

export const STATUS_LABELS = {
  0: 'Inactive',
  1: 'Active',
  2: 'Deleted',
  5: 'Requested Delete',
  11: 'Pending Verification',
  12: 'Cancellation Applied',
  13: 'Cancelled',
  14: 'Expired',
};

export const MIGRATION_STATUS_LABELS = {
  0: 'None',
  1: 'Imported',
  2: 'Login Attempted',
  3: 'Password Reset',
  4: 'Account Synced',
  5: 'Migrated',
};

export const STATUS_COLORS = {
  0: 'error',
  1: 'success',
  2: 'error',
  5: 'warning',
  11: 'warning',
  12: 'warning',
  13: 'error',
  14: 'error',
};

export const MIGRATION_STATUS_COLORS = {
  0: 'error',
  1: 'primary',
  2: 'primary',
  3: 'info',
  4: 'warning',
  5: 'success',
};

export const STATUS_ALL_VALUE = 99999;

export const ACTIONS = {
  CREATE: {
    value: 'create',
    label: 'Create New',
  },
  EDIT: {
    value: 'update',
    label: 'Edit',
  },
  VIEW: {
    value: 'view',
    label: 'View',
  },
  DELETE: {
    value: 'delete',
    label: 'Delete',
  },
  CHANGE_PASSWORD: {
    value: 'change_password',
    label: 'Change Password',
  },
  ASSIGN_CHANNELS: {
    value: 'assign_channels',
    label: 'Assign Channels',
  },
};
